import { Link as ChakraLink } from '@chakra-ui/react';
import NextLink from 'next/link';
import { LinkProps } from './types';
import { variants, common } from './variants';

export function ButtonLink({ children, href, mode = 'primary' }: LinkProps) {
  const styles = variants[mode] || {};

  return (
    <NextLink href={href} passHref>
      <ChakraLink {...common} {...(styles as any)}>
        {children}
      </ChakraLink>
    </NextLink>
  );
}
