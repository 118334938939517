import {
  Stack,
  Heading,
  Text,
  Input,
  FormControl,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { Button, Container } from '@ui';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { NewsletterProps } from './types';
import { emailRegex } from '@utils';

export function Newsletter({ id, subtitle, title }: NewsletterProps) {
  const toast = useToast();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = useCallback(
    async ({ email }: { email: string }) => {
      try {
        const token = await executeRecaptcha('NEWSLETTER');

        await fetch('/api/newsletter', {
          method: 'POST',
          body: email,
          headers: {
            recaptcha: token,
          },
        });

        toast({
          status: 'success',
          title: 'Zapisaliśmy Cię!',
          description: 'Będziemy Cię informować o nowych promocjach.',
          duration: 5000,
          isClosable: true,
          position: 'bottom-right',
        });

        reset();
      } catch (error) {
        console.error(error.message);
      }
    },
    [toast, reset, executeRecaptcha]
  );

  return (
    <Container
      id={id}
      position={'relative'}
      bg="brand.100"
      py={{ base: '10', md: '14', lg: '24' }}
    >
      <Flex
        direction={{ base: 'column', xl: 'row' }}
        alignItems={{ lg: 'center' }}
      >
        <Stack
          spacing={'2'}
          color={'white'}
          maxW={'4xl'}
          pr={{ base: 0, md: 12 }}
        >
          <Heading
            fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
            fontWeight={'800'}
            lineHeight={'shorter'}
          >
            {title}
          </Heading>

          <Text fontSize={{ base: 'sm', md: 'lg', lg: 'xl' }}>{subtitle}</Text>
        </Stack>

        <Stack
          direction={{ base: 'row', lg: 'column' }}
          width={'full'}
          maxW={{ lg: 'lg' }}
          ml={{ base: 'none', xl: 'auto' }}
          spacing={{ lg: 8, xl: 3 }}
          pt={{ base: 6, lg: 0 }}
        >
          <Text
            display={{ base: 'none', xl: 'block' }}
            fontSize="2xl"
            color="white"
            fontWeight={'800'}
          >
            Newsletter
          </Text>

          <Flex as="form" onSubmit={handleSubmit(onSubmit)}>
            <FormControl id="name">
              <Input
                w={{ base: '65vw', md: 'sm' }}
                bg="white"
                borderColor="gray.300"
                placeholder="Email"
                required
                type="email"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: emailRegex,
                    message: 'Podany email jest niewłaściwy',
                  },
                })}
              />
            </FormControl>

            <FormControl id="name" float="right" pl={'2'}>
              <Button type="submit" mode="secondary">
                Wyślij
              </Button>
            </FormControl>
          </Flex>
        </Stack>
      </Flex>
    </Container>
  );
}
