import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  Stack,
  Text,
  Flex,
  Icon,
  useToast,
} from '@chakra-ui/react';
import { MdOutlineEmail } from 'react-icons/md';
import { BsPerson } from 'react-icons/bs';
import { FaMapMarkerAlt, FaPhoneAlt, FaRegEnvelope } from 'react-icons/fa';
import { Button, Container, Link } from '@ui';
import { ContactProps } from './types';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { emailRegex } from '@utils';

export function Contact({
  id,
  title,
  subtitle,
  phones,
  emails,
  addresses,
}: ContactProps) {
  const toast = useToast();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = useCallback(
    async (data: { email: string; name: string; message: string }) => {
      try {
        const token = await executeRecaptcha('CONTACT_FORM');

        await fetch('/api/contact-form', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            recaptcha: token,
          },
        });

        toast({
          status: 'success',
          title: 'Dziękujemy za wiadomość!',
          description: 'Odpowiemy na Twoje pytania najszybciej jak to możliwe.',
          duration: 5000,
          isClosable: true,
          position: 'bottom-right',
        });

        reset();
      } catch (error) {
        console.error(error.message);
      }
    },
    [toast, reset, executeRecaptcha]
  );

  return (
    <Container id={id} py={{ base: '10', md: '20', lg: '32' }}>
      <Stack
        spacing={{ base: 10, md: 16, lg: 28 }}
        alignItems={{ lg: 'center' }}
      >
        <Box textAlign={{ lg: 'center' }}>
          <Text
            fontSize={{ base: '2xl', sm: '4xl' }}
            lineHeight="8"
            fontWeight="extrabold"
            letterSpacing="tight"
            color="brand.300"
          >
            {title}
          </Text>

          <Text
            maxW="2xl"
            mt={{ base: '3', md: '4' }}
            fontSize={{ base: 'sm', md: 'lg', lg: 'xl' }}
            mx={{ lg: 'auto' }}
            color={'gray.600'}
          >
            {subtitle}
          </Text>
        </Box>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          width={'100%'}
          alignItems={{ md: 'center' }}
          justifyContent={'center'}
        >
          <Stack spacing={{ base: 5, md: 8, lg: 10 }} flex={1} maxW={'md'}>
            <Flex alignItems={'center'}>
              <Icon
                h={{ base: 5, md: 8 }}
                w={{ base: 5, md: 8 }}
                color="brand.300"
                as={FaPhoneAlt}
              />
              <Stack
                px={{ base: 6, md: 10 }}
                spacing={'1'}
                color="brand.300"
                fontWeight={'500'}
              >
                {phones.map((phone, index) => (
                  <Text fontSize={{ base: 'sm', md: 'md' }} key={phone + index}>
                    {phone}
                  </Text>
                ))}
              </Stack>
            </Flex>

            <Flex alignItems={'center'}>
              <Icon
                h={{ base: 5, md: 8 }}
                w={{ base: 5, md: 8 }}
                color="brand.300"
                as={FaRegEnvelope}
              />
              <Stack
                px={{ base: 6, md: 10 }}
                spacing={'1'}
                color="brand.300"
                fontWeight={'500'}
              >
                {emails.map((email, index) => (
                  <Link
                    fontSize={{ base: 'sm', md: 'md' }}
                    key={email + index}
                    href={`mailto:${email}`}
                    textDecoration={'underline'}
                  >
                    {email}
                  </Link>
                ))}
              </Stack>
            </Flex>

            <Flex alignItems={'center'}>
              <Icon
                h={{ base: 5, md: 8 }}
                w={{ base: 5, md: 8 }}
                color="brand.300"
                as={FaMapMarkerAlt}
              />
              <Stack
                px={{ base: 6, md: 10 }}
                spacing={'1'}
                color="brand.300"
                fontWeight={'500'}
              >
                {addresses.map((address, index) => (
                  <Text
                    fontSize={{ base: 'sm', md: 'md' }}
                    key={address + index}
                  >
                    {address}
                  </Text>
                ))}
              </Stack>
            </Flex>
          </Stack>

          <Stack
            mt={{ base: 12, md: 0 }}
            spacing={{ base: 4, md: 5 }}
            alignItems={'center'}
            flex={1}
            maxW={'xl'}
            as="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl id="name">
              <FormLabel fontSize={{ base: 'sm', md: 'md' }}>
                Imię i Nazwisko
              </FormLabel>
              <InputGroup borderColor="#E0E1E7">
                <InputLeftElement pointerEvents="none">
                  <BsPerson color="gray.700" />
                </InputLeftElement>

                <Input
                  type="text"
                  size="md"
                  placeholder="Imię i nazwisko"
                  required
                  {...register('name', { required: true })}
                />
              </InputGroup>
            </FormControl>

            <FormControl id="name">
              <FormLabel fontSize={{ base: 'sm', md: 'md' }}>Email</FormLabel>
              <InputGroup borderColor="#E0E1E7">
                <InputLeftElement pointerEvents="none">
                  <MdOutlineEmail color="gray.700" />
                </InputLeftElement>

                <Input
                  type="email"
                  size="md"
                  placeholder="Adres email"
                  required
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: emailRegex,
                      message: 'Podany email jest niewłaściwy',
                    },
                  })}
                />
              </InputGroup>
            </FormControl>

            <FormControl id="name">
              <FormLabel fontSize={{ base: 'sm', md: 'md' }}>
                Wiadomość
              </FormLabel>
              <Textarea
                borderColor="gray.300"
                placeholder="Wiadomość"
                required
                {...register('message', { required: true })}
              />
            </FormControl>

            <FormControl id="name">
              <Button type="submit" mode="secondary">
                Wyślij
              </Button>
            </FormControl>
          </Stack>
        </Flex>
      </Stack>
    </Container>
  );
}
