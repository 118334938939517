import {
  Box,
  Flex,
  SimpleGrid,
  Image,
  Text,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { ButtonLink, Container } from '@ui';
import { PromoProps } from './types';

export function Promo({
  id,
  title,
  subtitle,
  description,
  links,
  variant,
  isMain,
}: PromoProps) {
  const isInGrid = variant === 'grid';

  return (
    <Container
      id={id}
      py={isInGrid ? { base: '10', md: '14', lg: '32' } : undefined}
    >
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={0}>
        {isInGrid ? (
          <Flex>
            <Image
              src={'/images/promo.jpg'}
              alt="3 women looking at a laptop"
              fit="cover"
              h={{ base: 64, md: '50vw', lg: 'full' }}
              w={{ md: 'full' }}
              bg="gray.100"
              loading="lazy"
              opacity={0.4}
            />
          </Flex>
        ) : (
          <Flex
            flex={{ sm: 0, md: 1 }}
            position={'relative'}
            height={'50vw'}
            maxH={'60vh'}
            minH={'300px'}
          >
            <Box
              bg="brand.100"
              position={'absolute'}
              right={{ base: '-16px', lg: 0 }}
              top={'0'}
              bottom={'0'}
              width={{ base: '100vw', lg: '50vw' }}
              backgroundSize={'cover'}
              backgroundPosition={'center'}
              backgroundImage={'/images/promo.jpg'}
              opacity={0.4}
            />
          </Flex>
        )}

        <Stack
          px={{ base: 0, lg: 20 }}
          pt={{ base: 6, md: 12, lg: 0 }}
          spacing={{ base: 6, md: 7, lg: 10 }}
          justifyContent={'center'}
        >
          <Flex direction="column" alignItems="start" justifyContent="center">
            <Text
              color={'brand.300'}
              fontSize={{ base: 'md', lg: 'lg' }}
              textTransform="uppercase"
              fontWeight="extrabold"
              pb={{ base: 2, lg: 0 }}
            >
              {title}
            </Text>

            <Heading
              as={isMain ? 'h1' : 'h2'}
              fontSize={{ base: '3xl', lg: '5xl' }}
              fontWeight="bold"
              color={'brand.300'}
              lineHeight={'shorter'}
            >
              {subtitle}
            </Heading>
          </Flex>

          <Text fontSize={{ base: 'sm', md: 'lg' }} color={'gray.600'}>
            {description}
          </Text>

          {links.length > 0 && (
            <Flex>
              {links.map((link) => (
                <ButtonLink key={link.id} href={link.href} mode={link.mode}>
                  {link.text}
                </ButtonLink>
              ))}
            </Flex>
          )}
        </Stack>
      </SimpleGrid>
    </Container>
  );
}
