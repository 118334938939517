import { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import NProgress from 'nprogress';
import Router from 'next/router';
import 'focus-visible/dist/focus-visible';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Cookies, cookiesGlobalStyles } from '@components';
import { useConfig } from '@hooks';

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

Router.events.on('routeChangeStart', () => {
  const html = document.querySelector('html');
  html.style.scrollBehavior = 'auto';
});
Router.events.on('routeChangeComplete', () => {
  const html = document.querySelector('html');
  html.style.scrollBehavior = 'smooth';
});

function CustomApp({ Component, pageProps }: AppProps) {
  const config = useConfig();

  const theme = extendTheme({
    styles: {
      global: {
        ...cookiesGlobalStyles,
        html: {
          scrollBehavior: 'smooth',
          overflowX: 'hidden',
        },
        '.js-focus-visible :focus:not([data-focus-visible-added])': {
          outline: 'none',
          boxShadow: 'none',
        },
        '#nprogress': {
          pointerEvents: 'none',
        },
        '#nprogress .bar': {
          backgroundColor: 'brand.100',
          position: 'fixed',
          zIndex: 1000,
          top: 0,
          left: 0,
          width: '100%',
          height: '4px',
        },
      },
    },
    colors: {
      brand: {
        100: '#96C22D',
        200: '#F19464',
        300: '#2D2D2D',
      },
    },
  });

  return (
    <GoogleReCaptchaProvider
      scriptProps={{
        async: true,
      }}
      reCaptchaKey={config.google_recaptcha_v3_public_key}
    >
      <ChakraProvider theme={theme}>
        <Component {...pageProps} />

        <Cookies />
      </ChakraProvider>
    </GoogleReCaptchaProvider>
  );
}

export default CustomApp;
