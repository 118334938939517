import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Stack,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import { Link } from '@ui';
import { useRouter } from 'next/router';
import { NavigationItem } from './types';

interface Props {
  elements: NavigationItem[];
}

export function DesktopNav({ elements }: Props) {
  const { asPath } = useRouter();

  return (
    <Stack direction={'row'} spacing={4} mt={'2'}>
      {elements.map((navItem) => {
        const isMain = asPath === '/' && navItem.href === '/#';

        const isBlog =
          asPath.startsWith(navItem.href) && navItem.href === '/blog';

        const isActive = asPath === navItem.href || isBlog || isMain;

        return (
          <Box key={navItem.id}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <Link
                  p={3}
                  href={navItem.href}
                  color={'brand.300'}
                  fontSize={{ base: 'md', lg: 'lg' }}
                  fontWeight="600"
                  textDecoration={isActive ? 'underline' : 'none'}
                  _hover={{
                    textDecoration: 'underline',
                  }}
                >
                  {navItem.label}
                </Link>
              </PopoverTrigger>

              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={'white'}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}
                >
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        );
      })}
    </Stack>
  );
}

function DesktopSubNav({ label, href, subLabel }: NavigationItem) {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: 'pink.50' }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
}
