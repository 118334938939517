import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';
import { variants, common } from './variants';
import { ButtonProps } from './types';

export function Button({
  children,
  mode = 'primary',
  ...props
}: ButtonProps & ChakraButtonProps) {
  const styles = variants[mode] || {};

  return (
    <ChakraButton {...common} {...(styles as any)} {...props}>
      {children}
    </ChakraButton>
  );
}
