import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ButtonLink, Container } from '@ui';
import { HeroProps } from './types';

export function Hero({ id, heading, description, links }: HeroProps) {
  return (
    <Container id={id}>
      <Stack
        height={'50vw'}
        maxH={'75vh'}
        minH={{ base: '400px', md: '50vh', lg: 'auto' }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Flex flex={1} align={'center'} justify={'center'}>
          <Stack spacing={{ base: '6' }} w={'full'}>
            <Heading as="h1" fontSize={{ base: '4xl', lg: '5xl', xl: '6xl' }}>
              <Text
                as={'span'}
                color="brand.300"
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: useBreakpointValue({ base: '20%', md: '30%' }),
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: 'brand.100',
                  zIndex: -1,
                }}
              >
                {heading.main}
              </Text>
              <br />{' '}
              <Text color={'brand.100'} as={'span'}>
                {heading.sub}
              </Text>{' '}
            </Heading>

            <Text
              fontSize={{ base: 'md', md: 'lg' }}
              color={'gray.700'}
              maxW={'lg'}
            >
              {description}
            </Text>

            <Stack direction={{ base: 'row' }} spacing={{ base: '2', md: '4' }}>
              {links.map((link) => (
                <Flex key={link.id}>
                  <ButtonLink href={link.href} mode={link.mode}>
                    {link.text}
                  </ButtonLink>
                </Flex>
              ))}
            </Stack>
          </Stack>
        </Flex>

        <Flex flex={{ sm: 0, md: 1 }} position={'relative'}>
          <Box
            bg="brand.300"
            position={'absolute'}
            left={'0'}
            top={'0'}
            bottom={'0'}
            width={'50vw'}
            backgroundSize={'cover'}
            backgroundPosition={'center'}
            backgroundImage={{ md: '/images/hero_medium.jpg' }}
          />
        </Flex>
      </Stack>
    </Container>
  );
}
