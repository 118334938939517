import { Box, SimpleGrid, Text, Heading, Stack } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import { Container } from '@ui';
import { AboutProps, ElementProps } from './types';

export function About({ id, title, subtitle, elements }: AboutProps) {
  return (
    <Container id={id} bg={'gray.50'} py={{ base: '10', md: '24', lg: '32' }}>
      <Stack spacing={{ base: '12', md: 20, lg: '28' }}>
        <Box textAlign={{ md: 'center' }}>
          <Text
            fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
            lineHeight="8"
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            {title}
          </Text>

          <Text
            mt={{ base: '3', md: '4' }}
            maxW="2xl"
            fontSize={{ base: 'sm', md: 'lg', lg: 'xl' }}
            mx={{ md: 'auto' }}
            color={'gray.600'}
          >
            {subtitle}
          </Text>
        </Box>

        <Stack spacing={{ base: '12', md: 24, lg: '32' }}>
          {elements.map((element, index) => {
            return <Element key={element.id} index={index} {...element} />;
          })}
        </Stack>
      </Stack>
    </Container>
  );
}

function Element({
  title,
  text,
  image,
  index,
}: ElementProps & { index: number }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '200px',
    initialInView: false,
  });

  return (
    <Box ref={ref}>
      <SimpleGrid
        alignItems="center"
        columns={{ base: 1, md: 2 }}
        flexDirection="column-reverse"
        spacingX={{ base: 10, md: 16, lg: 24 }}
      >
        <Box order={{ base: 'none', md: (index + 1) % 2 } as any}>
          <Heading
            mb={{ base: '2', md: '4' }}
            fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }}
            fontWeight="extrabold"
            letterSpacing="tight"
            textAlign={{ base: 'left' }}
            lineHeight={{ md: 'shorter' }}
          >
            {title}
          </Heading>

          <Text
            mb={{ base: 6, md: 5 }}
            textAlign={{ base: 'left' }}
            color={'gray.600'}
            fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
          >
            {text}
          </Text>
        </Box>

        <Box
          w="full"
          h="full"
          py={{ base: 20, md: 36, lg: 48 }}
          backgroundSize={'cover'}
          backgroundPosition={'center'}
          backgroundImage={inView ? image : undefined}
          position={'relative'}
          opacity={0.4}
        />
      </SimpleGrid>
    </Box>
  );
}
