import {
  Flex,
  Icon,
  Image,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Container, Link } from '@ui';
import { FaFacebookSquare } from 'react-icons/fa';
import { FooterProps } from './types';
import { useInView } from 'react-intersection-observer';

export function Footer({ id, logo, categories }: FooterProps) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '500px',
    initialInView: false,
  });

  return (
    <Container
      id={id}
      bg={'gray.50'}
      color={'brand.300'}
      py={{ base: '6', lg: '10' }}
    >
      <Stack ref={ref}>
        <SimpleGrid
          spacing={4}
          templateColumns={{
            sm: '1fr 1fr',
            md: '2fr 1fr 1fr 1fr',
            lg: '3fr 1fr 1fr 1fr',
          }}
        >
          <Stack spacing={6}>
            <Link href={logo.href}>
              <Image
                height={{ base: '32px', lg: '38px' }}
                alt="Logo"
                src={logo.imageUrl}
              />
            </Link>

            <Text fontSize={'sm'}>
              © {new Date().getFullYear()} IKSEL Studio Reklamy. Wszelkie prawa
              zastrzeżone.
            </Text>
          </Stack>

          {categories.map((category) => {
            return (
              <Stack align={'flex-start'} key={category.name}>
                <Text
                  fontWeight={'500'}
                  fontSize={{ base: 'md', lg: 'lg' }}
                  mb={2}
                >
                  {category.name}
                </Text>

                {category.elements.map((link) => {
                  return (
                    <Link
                      fontSize={{ base: 'sm', lg: 'md' }}
                      key={link.href}
                      href={link.href}
                    >
                      {link.text}
                    </Link>
                  );
                })}
              </Stack>
            );
          })}

          <Flex alignItems={'center'}>
            <Link
              h={{ base: 8, xl: 10 }}
              href="https://www.facebook.com/IKSEL.reklama/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                w={{ base: 8, xl: 10 }}
                h={{ base: 8, xl: 10 }}
                as={FaFacebookSquare}
                color={'#3b5998'}
              />
            </Link>
          </Flex>
        </SimpleGrid>
      </Stack>
    </Container>
  );
}
