import { Box, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { Container } from '@ui';
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MapProps } from './types';

export function Map({ id, title, subtitle, sections, map }: MapProps) {
  return (
    <Container id={id} py={{ base: '10', md: '20', lg: '32' }}>
      <Stack spacing={{ base: 8, md: 14, lg: 20 }}>
        <Box textAlign={{ lg: 'center' }}>
          <Text
            fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
            lineHeight="8"
            fontWeight="extrabold"
            letterSpacing="tight"
            color="brand.300"
          >
            {title}
          </Text>

          <Text
            mt={{ base: '3', md: '4' }}
            maxW="2xl"
            fontSize={{ base: 'sm', md: 'lg', lg: 'xl' }}
            mx={{ lg: 'auto' }}
            color={'gray.600'}
          >
            {subtitle}
          </Text>
        </Box>

        <Flex
          direction={{ base: 'column', lg: 'row' }}
          alignItems={{ lg: 'center' }}
        >
          <Stack
            direction={{ md: 'row', lg: 'column' }}
            w={{ lg: '25%' }}
            spacing={{ base: 4, md: 12 }}
          >
            {sections.map((section, index) => (
              <Stack
                flex={1}
                key={section.name + index}
                spacing={{ base: 1, md: 4 }}
              >
                <Text
                  fontSize={{ base: 'md', md: 'xl' }}
                  color="brand.100"
                  fontWeight={'900'}
                >
                  {section.name}
                </Text>

                <Stack spacing={{ base: 0, md: '2px' }} color="brand.300">
                  {section.elements.map((element, index) => (
                    <Text
                      fontSize={{ base: 'sm', md: 'md' }}
                      key={element + index}
                    >
                      {element}
                    </Text>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>

          <Box
            w={{ lg: '75%' }}
            h={{ base: 'sm', lg: 'lg' }}
            mt={{ base: 8, md: 10, lg: 0 }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{
                key: map.key,
              }}
              defaultCenter={{
                lat: map.lat,
                lng: map.lng,
              }}
              defaultZoom={map.zoom}
              yesIWantToUseGoogleMapApiInternals
            >
              <Icon
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                lat={map.lat}
                lng={map.lng}
                as={FaMapMarkerAlt}
                h={'10'}
                w={'10'}
                color="brand.100"
                transform="translate(-50%, -75%)"
              />
            </GoogleMapReact>
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}
