import { Category, ProductElement } from './types';

const poligrafia: ProductElement[] = [
  {
    image: '/produkty/wizytowki.png',
    title: 'Wizytówki',
    text: `Produkujemy je w wymiarach 90x50mm i 85x55mm na grubym papierze kredowym 350g. Możemy dodatkowo uszlachetnić je folią błyszczącą lub matową, dodać lakier UV lub 3D.`,
  },
  {
    image: '/produkty/ulotki.png',
    title: 'Ulotki',
    text: `Jedna z najpopularniejszych form reklamy. W naszej ofercie znajdziesz ulotki różnej wielkości oraz z różnym wykończeniem.`,
  },
  {
    image: '/produkty/ulotki-skladane.png',
    title: 'Ulotki składane',
    text: `Wiele możliwości formatów i ich składania. Różne gramatury papieru. Druk cyfrowy lub offsetowy. Krótkie terminy realizacji.`,
  },
  {
    image: '/produkty/plakaty.png',
    title: 'Plakaty',
    text: `Plakaty są doskonałą formą przekazu, często informującym o jakimś zdarzeniu. W naszej ofercie znajdują się plakaty różnego formatu, drukowane na papierze kredowym o różnej gramaturze.`,
  },
  {
    image: '/produkty/notesy.png',
    title: 'Notesy',
    text: `Bardzo praktyczna forma reklamy znajdująca zastosowanie praktycznie w każdej sytuacji. W naszej ofercie znajdują się notesy w trzech różnych rozmiarach, z okładką bądź bez, oraz z różną ilością kart.`,
  },
  {
    image: '/produkty/teczki.png',
    title: 'Teczki',
    text: `Podstawowe wyposażenie każdego biura. Teczki drukujemy na grubym papierze kredowym o gramaturze 350g. Możemy je dodatkowo pokryć folią matową bądź błyszczącą.`,
  },
  {
    image: '/produkty/karty-plastikowe.png',
    title: 'Karty plastikowe',
    text: `Karty plastikowe idealnie sprawdzają się jako upominki, bony lub karty stałego klienta. Możemy także wykonać karty z paskami do podpisu, chipami, złoceniem oraz tłoczeniem.`,
  },
  {
    image: '/produkty/papier-firmowy.png',
    title: 'Papier firmowy',
    text: `Tym co wyróżnia firmę spośród innych działających na rynku jest jej własny papier firmowy. Na papierze firmowym drukowane są oficjalne pisma, umowy i faktury.`,
  },
  {
    image: '/produkty/katalogi.png',
    title: 'Katalogi',
    text: `Oferujemy druk katalogów zszywanych, klejonych bądź spiralowanych w różnych formatach. Różne wykończenia i grubości zarówno okładek jak i wnętrza.`,
  },
  {
    image: '/produkty/koperty.png',
    title: 'Koperty',
    text: `W czasach nowej sprzedaży wysyłkowej, koperty reklamowe opatrzone grafiką naszej firmy są bardzo miłym i ciekawym dodatkiem. W naszej ofercie znajdziesz koperty różnych wymiarów.`,
  },
  {
    image: '/produkty/naklejki.png',
    title: 'Naklejki',
    text: `Drukowane na folii lub papierze samoprzylepnym. Dowolny kształt, wymiar i ilość. Tania i trwała forma reklamy.`,
  },
  {
    title: 'Segregatory',
    image: '/produkty/segregatory.png',
    text: 'Segregatory firmowe to niezwykle praktyczny element wyposażenia biura. Trwałe i estetyczne, są udaną inwestycją w wizerunek firmy. Znakomicie prezentują się na półce i stanowią efektowną oprawę przechowywanych dokumentów.',
  },
  {
    title: 'Podkłady na biurko',
    image: '/produkty/podklady-na-biurko.png',
    text: 'Planer to estetyczny a jednocześnie funkcjonalny produkt, który powinien znaleźć się na każdym biurku. Szybka notatka czy oznaczenie daty w kalendarzu. Wszystko zawsze pod ręką.',
  },
  {
    image: '/produkty/kalendarzyki.png',
    title: 'Kalendarzyki',
    text: `Kalendarzyki są niedrogą i całoroczną reklamą Twojej firmy. Kalendarzyki drukujemy na papierze kredowym o gramaturze 350g, następnie pokrywamy je folią i zaokrąglamy rogi.`,
  },
  {
    image: '/produkty/kalendarze.png',
    title: 'Kalendarze',
    text: `W ofercie posiadamy kalendarze książkowe, trójdzielne, jednodzielne, piramidka, planszowe oraz listwowane. Różne formaty i wykończenia.`,
  },
  {
    image: '/produkty/zaproszenia.png',
    title: 'Zaproszenia',
    text: `W naszej ofercie znajdziesz do wyboru zaproszenia różnych formatów, wydrukowane na różnych papierach.`,
  },
];

const drukWielkoformatowy: ProductElement[] = [
  {
    image: '/produkty/banery.png',
    title: 'Banery',
    text: `Trwała i skuteczna zewnętrzna forma reklamy - często stosowana przy najbardziej uczęszczanych ulicach. Produkt jest wykonany z mocnego, trwałego materiału odpornego na warunki atmosferyczne.`,
  },
  {
    title: 'Siatki mesh',
    text: 'Siatki mesh umieszczane są na ścianach budynków, nad drogami, na ogrodzeniach gdzie nie mogą zostać niezauważone. Lekka i przepuszczająca światło nie ogranicza widoczności od wewnątrz.',
    image: '/produkty/siatka-mesh.png',
  },
  {
    image: '/produkty/folie.png',
    title: 'Folie',
    text: `Najwyższej jakości materiał zapewnia doskonały wydruk grafiki. Najczęściej wykorzystywana do wyklejania płaskich powierzchni takich jak: blacha, szyby, tablice informacyjne oraz do wydruków etykiet czy naklejek informacyjnych.`,
  },
  {
    image: '/produkty/tablice-pcv.png',
    title: 'Tablice PCV',
    text: `Tablice są lekkie i trwałe doskonale nadają się na szyldy, tablice reklamowe czy plansze. Nadruk bezpośredni UV i możliwość docięcia do pożądanego kształtu.`,
  },
  {
    title: 'Magnesy',
    text: 'Nie chcesz oklejać auta? Reklama na magnesie rozwiąże Twój problem. Możesz ją zdjąć kiedy tylko chcesz a jadąc do Klienta ponownie przykleić. Wysokiej jakości wydruk odporny na warunki atmosferyczne.',
    image: '/produkty/magnesy.png',
  },
  {
    title: 'Kasetony',
    text: 'Wykonujemy różnego rodzaju reklamy świetlne. Kasetony, pylony szyldy. Świadczymy także usługi projektowania i montażu.',
    image: '/produkty/kasetony.png',
  },
];

const gadzety: ProductElement[] = [
  {
    image: '/produkty/dlugopisy.png',
    title: 'Długopisy',
    text: `W ofercie posiadamy długopisy metalowe, plastikowe a nawet papierowe. Reklamę nadrukujemy lub wygrawerujemy.`,
  },
  {
    image: '/produkty/koszulki-z-nadrukiem.png',
    title: 'Koszulki z nadrukiem',
    text: `Bardzo szeroka gama odzieży do znakowania. Dowolny trwały nadruk, także złoty lub srebrny. Realizacje już od jednej sztuki!`,
  },
  {
    image: '/produkty/smycze-sublimacyjne.png',
    title: 'Smycze sublimacyjne',
    text: `Drukujemy w szerokościach 10mm, 15mm, 20mm lub 25mm. Możliwość druku jednostronnego lub dwustronnego w pełnym kolorze. Różne rodzaje zakończeń.`,
  },
  {
    image: '/produkty/smycze-z-lacznikiem.png',
    title: 'Smycze z łącznikiem',
    text: `Z odpinaną końcówką z różnymi możliwościami zakończenia. Drukujemy w szerokościach 10mm, 15mm, 20mm lub 25mm.`,
  },
  {
    image: '/produkty/smycze-naszywane.png',
    title: 'Smycze naszywane',
    text: `Taśmę z nadrukiem naszywamy na taśmę podkładową dzięki czemu smycze są grube i prestiżowe. Dostępne wymiary to 15/20mm oraz 20/25mm. Możliwość odpinanej końcówki.`,
  },
  {
    title: 'Torby z nadrukiem',
    image: '/produkty/torby-z-nadrukiem.png',
    text: 'Ekologiczne papierowe, laminowane czy materiałowe. Dowolny nadruk w pełnym kolorze. Duża ilość rozmiarów i kolorów.',
  },
  {
    title: 'USB',
    image: '/produkty/usb.png',
    text: 'Ogromna ilość różnych wzorów i kolorów, nadruk bądź grawer laserowy. Pojemności od 1Gb do 64Gb. Dodatkowo możliwość zamówienia indywidualnych pudełeczek.',
  },
  {
    title: 'Zapalniczki',
    image: '/produkty/zapalniczki.png',
    text: 'Zapalniczka firmowa sprawdzi się jako gadżet promocyjny na targi lub jako dodatek do firmowych pakietów prezentowych. Zadruk praktycznie na całej powierzchni. Różne wzory i kolory do wyboru.',
  },
  {
    title: 'Pieczątki',
    image: '/produkty/pieczatki.png',
    text: 'Pieczątka automatyczna to praktyczne wyposażenie każdego biura. Jest wygodna w użyciu, a dzięki możliwości wyboru aż 5 rozmiarów płytki tekstowej, możesz dopasować ją do wielu zastosowań (i stanowisk).',
  },
  {
    title: 'Kubki',
    image: '/produkty/kubki.png',
    text: 'Ciekawa forma reklamy jako gadżet bądź prezent. Druk w pełnym kolorze. Ogromna ilość wzorów od tanich kubków po eksluzywne zestawy. Możliwość zamówienia od jednej sztuki.',
  },
  {
    title: 'Płytki grawerowane',
    image: '/produkty/plytki-grawerowane.png',
    text: 'Posiadamy laser CO2 dzięki czemu możemy wykonać dowolną tabliczkę, identyfikator lub ciekawą formę podziękowań.',
  },
];

const systemyWystawiennicze: ProductElement[] = [
  {
    title: 'Roll up',
    text: `Idealne rozwiązanie na targi czy imprezy promocyjne. Bardzo łatwy i szybki montaż. Możliwość zamówienia samych wydruków.`,
    image: '/produkty/roll-up.png',
  },
  {
    title: 'Potykacze',
    text: 'Idealna reklama przed biuro lub do wewnątrz. Nie da się przejść koło niej obojętnie. Różne rozmiary dostosowane do Twoich potrzeb. Łatwa wymiana grafiki.',
    image: '/produkty/potykacze.png',
  },
  {
    title: 'Ścianki reklamowe',
    text: 'Lekkie, przenośne konstrukcje z Twoją reklamą. Ścianki reklamowe możemy wykonać w dowolnym rozmiarze i kształcie. Łatwe w montażu i demontażu.',
    image: '/produkty/scianki-reklamowe.png',
  },
];

// const www: ProductElement[] = [
//   {
//     image: '/produkty/strony-internetowe.png',
//     title: 'Strony internetowe',
//     text: `Oferujemy tworzenie wizytówek firmy, bądź też rozbudowanych serwisów internetowych. W zależności od potrzeb może to być prosta strona z informacjami o ofercie firmy bądź zaawansowana strona z możliwością dodawania zdjęć, aktualności czy prowadzenia bloga.`,
//   },
//   {
//     image: '/produkty/sklep-internetowy.png',
//     title: 'Sklep internetowy',
//     text: `Sklep może zostać zbudowany na popularnych silnikach sklepowych, lub też możemy przygotować indywidualny projekt, dostosowany tylko do Państwa potrzeb`,
//   },
//   {
//     image: '/produkty/media-spolecznosciowe.png',
//     title: 'Media społecznościowe',
//     text: `Możemy stworzyć dla Ciebie oryginalny Fan Page oraz na bieżąco go prowadzić, wciąż powiększając ilość potencjalnych nowych Klientów. Jest to prosta droga do osiągnięcia sukcesu, zwiększenia sprzedaży oraz wzmocnienia wizerunku firmy.`,
//   },
//   {
//     image: '/produkty/pozycjonowanie-www.png',
//     title: 'Pozycjonowanie stron internetowych',
//     text: `W dobie internetu, dobre wypromowanie strony jest kluczowym procesem. Jest to długi i skomplikowany proces natomiast efekty są nieprzeliczalne. Zaufaj naszym specjalistom od optymalizacji SEO.`,
//   },
// ];

// const uslugi: ProductElement[] = [
//   {
//     image: '/produkty/logo-firmowe.png',
//     title: 'Logo firmowe',
//     text: `Nasz zespół stworzy oryginalny i niepowtarzalny wizerunek Państwa firmy. Dla ułatwienia stworzymy kilka propozycji z których logo będziemy modyfikować do momentu pełnej Państwa satysfakcji.`,
//   },
//   {
//     image: '/produkty/redagowanie-tekstow.png',
//     title: 'Redagowanie tekstów',
//     text: `Wieloletnie doświadczenie oraz doskonała znajomość tajników reklamy sprawia, że odpowiednio dobrane słowa potrafią kreować decyzje i wybory odbiorców oraz być budulcem pozytywnego wizerunku firmy, marki bądź samego produktu.`,
//   },
//   {
//     image: '/produkty/projektowanie-graficzne.png',
//     title: 'Projektowanie graficzne',
//     text: `Specjalizujemy się we wszelkiego rodzaju projektach graficznych materiałów reklamowych począwszy od wizytówek po wielostronicowe katalogi.`,
//   },
// ];

export const categories: Category[] = [
  {
    name: 'Poligrafia',
    type: 'POLIGRAFIA',
    elements: poligrafia,
  },
  {
    name: 'Druk wielkoformatowy',
    type: 'BIG_FORMATS',
    elements: drukWielkoformatowy,
  },
  {
    name: 'Gadżety reklamowe',
    type: 'GADGETS',
    elements: gadzety,
  },
  // {
  //   name: 'Internet',
  //   type: 'WWW',
  //   elements: www,
  // },
  // {
  //   name: 'Usługi',
  //   type: 'SERVICES',
  //   elements: uslugi,
  // },
  {
    name: 'Systemy wystawiennicze',
    type: 'SYSTEMS',
    elements: systemyWystawiennicze,
  },
];
