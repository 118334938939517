import {
  Heading,
  SimpleGrid,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Container } from '@ui';
import { StatisticsProps, StatsCardProps } from './types';

function StatsCard({ title, stat }: StatsCardProps) {
  return (
    <Stat
      bg="white"
      px={{ base: 5, md: 6, lg: 8 }}
      py={{ base: 5 }}
      shadow={useBreakpointValue({ base: 'lg', lg: 'xl' })}
      border={'1px solid'}
      borderColor={'gray.100'}
      rounded={'lg'}
    >
      <StatLabel fontWeight={{ base: '400', md: '500' }} isTruncated>
        {title}
      </StatLabel>

      <StatNumber
        fontSize={{ base: 'xl', lg: '2xl' }}
        fontWeight={{ base: '500' }}
      >
        {stat}
      </StatNumber>
    </Stat>
  );
}

export function Statistics({ id, title, elements }: StatisticsProps) {
  return (
    <Container id={id} bg="gray.50" py={{ base: '10', md: '14', lg: '24' }}>
      <Stack spacing={{ base: '7', md: '10', lg: '12' }}>
        <Heading
          textAlign={{ base: 'center' }}
          fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
          fontWeight={'bold'}
          color={'brand.300'}
        >
          {title}
        </Heading>

        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={{ base: 5, lg: 8 }}
          color={'brand.300'}
        >
          {elements.map((stat) => (
            <StatsCard
              key={stat.id}
              id={stat.id}
              title={stat.title}
              stat={stat.stat}
            />
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  );
}
