import getConfig from 'next/config';

interface Config {
  version: string;
  environment: 'development' | 'production';
  google_map_key: string;
  google_analytics_key: string;
  google_recaptcha_v3_public_key: string;
}

export function useConfig(): Config {
  const { publicRuntimeConfig } = getConfig();

  return {
    version: publicRuntimeConfig.VERSION,
    environment: publicRuntimeConfig.NODE_ENV,
    google_map_key: publicRuntimeConfig.GOOGLE_MAP_KEY,
    google_analytics_key: publicRuntimeConfig.GOOGLE_ANALYTICS_KEY,
    google_recaptcha_v3_public_key:
      publicRuntimeConfig.GOOGLE_CAPTCHA_V3_PUBLIC_KEY,
  };
}
