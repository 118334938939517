import { PressableMode } from './types';

export const common = {
  px: { base: '3.5', md: 4, lg: '5' },
  py: { base: 2.5, lg: '3' },
  fontSize: { base: 'sm', md: 'md' },
  border: 'solid transparent',
  fontWeight: 'bold',
  rounded: 'md',
  shadow: 'md',
  lineHeight: '1',
};

export const variants: Record<PressableMode, unknown> = {
  primary: {
    color: 'white',
    bg: 'brand.100',
    _hover: {
      bg: 'brand.200',
    },
  },
  secondary: {
    color: 'brand.300',
    bg: 'gray.100',
    _hover: {
      bg: 'gray.200',
    },
  },
};
