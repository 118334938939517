import { Stack, Box, Text, Flex } from '@chakra-ui/react';
import { Container } from '@ui';
import { PlainProps } from './types';

export function Plain({ id, title, subtitle, html }: PlainProps) {
  return (
    <Container id={id} py={{ base: '10', md: '24', lg: '28' }}>
      <Stack spacing={{ base: '10', md: '16', lg: '24' }}>
        <Box textAlign={{ md: 'center' }}>
          <Text
            as="h1"
            fontSize={{ base: '3xl', md: '4xl' }}
            lineHeight="8"
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            {title}
          </Text>

          <Text
            mt={{ base: '3', md: '4' }}
            maxW="2xl"
            fontSize={{ base: 'sm', md: 'lg', lg: 'xl' }}
            mx={{ md: 'auto' }}
            color={'gray.600'}
          >
            {subtitle}
          </Text>
        </Box>

        <Flex>
          <Box
            mx="auto"
            maxW={'5xl'}
            color="brand.300"
            fontSize={{ base: 'md' }}
            dangerouslySetInnerHTML={{ __html: html }}
            __css={{
              h2: {
                fontWeight: '700',
                fontSize: '2xl',
                mb: 6,
              },
              h3: {
                fontWeight: '700',
                fontSize: 'xl',
                mb: 6,
              },
              h4: {
                fontWeight: '700',
                fontSize: 'lg',
                mb: 4,
              },
              p: {
                mb: 6,
              },
              ul: {
                pl: 7,
                mb: 6,
              },
              ol: {
                pl: 7,
                mb: 6,
              },
              li: {
                py: '2px',
              },
            }}
          />
        </Flex>
      </Stack>
    </Container>
  );
}
