import {
  Flex,
  IconButton,
  Collapse,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Container, Link } from '@ui';
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';
import { HeaderProps } from './types';

export function Header({ id, logo, menu }: HeaderProps) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Container
      id={id}
      bg="white"
      borderBottom={1}
      borderStyle={'solid'}
      borderColor={'gray.200'}
      position="sticky"
      top="0"
      zIndex="100"
    >
      <Flex minH={{ base: '60px', md: '70px', lg: '80px' }} align={'center'}>
        <Flex
          flex={{ md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>

        <Flex
          flex={{ base: 1 }}
          justify={{ base: 'center', md: 'start' }}
          alignItems={'center'}
        >
          <Link href={logo.href} ml={{ base: '-20px', md: '0' }}>
            <Image
              height={{ base: '32px', lg: '38px' }}
              alt="Logo"
              src={logo.imageUrl}
            />
          </Link>

          <Flex
            display={{ base: 'none', md: 'flex' }}
            ml={{ md: 6, xl: 14 }}
            mt={{ md: '-6px' }}
          >
            <DesktopNav elements={menu.elements} />
          </Flex>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav elements={menu.elements} />
      </Collapse>
    </Container>
  );
}
