import { Text } from '@chakra-ui/react';
import CookieConsent from 'react-cookie-consent';

export const cookiesGlobalStyles = {
  '.cookies-button-wrapper': {
    alignSelf: 'center',
    px: { base: 4 },
    pt: { base: 3, md: 0 },
    mr: { base: 5 },
  },
};

export function Cookies() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Zamknij"
      cookieName="iksel-cookie-consent"
      style={{
        background: '#111',
        padding: '15px 0',
        alignItems: 'center',
      }}
      buttonWrapperClasses="cookies-button-wrapper"
      contentStyle={{
        margin: 0,
      }}
      buttonStyle={{
        color: '#000',
        fontSize: '0.8rem',
        background: '#fff',
        margin: 0,
      }}
      expires={150}
    >
      <Text
        fontSize={{ base: 'sm' }}
        px={{ base: 4 }}
        fontWeight={{ base: 400, lg: 500 }}
      >
        Na naszej stronie stosujemy pliki cookies. Korzystanie z witryny oznacza
        zgodę na ich zapis lub odczyt według ustawień przeglądarki.
      </Text>
    </CookieConsent>
  );
}
