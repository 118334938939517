import {
  Box,
  SimpleGrid,
  Stack,
  Heading,
  Text,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Container, Link } from '@ui';
import { useInView } from 'react-intersection-observer';
import { FeatureProps, ProductElement } from './types';

export function Features({ id, title, subtitle, categories }: FeatureProps) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '400px',
    initialInView: false,
  });

  return (
    <Container id={id} py={{ base: '10', md: '24', lg: '32' }}>
      <Stack spacing={{ base: '8', md: '12', lg: '20' }} ref={ref}>
        <Box textAlign={{ lg: 'center' }}>
          <Text
            fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
            lineHeight="8"
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            {title}
          </Text>

          <Text
            mt={{ base: '3', md: '4' }}
            maxW="2xl"
            fontSize={{ base: 'sm', md: 'lg', lg: 'xl' }}
            mx={{ lg: 'auto' }}
            color={'gray.600'}
          >
            {subtitle}
          </Text>
        </Box>

        <Tabs
          isFitted
          variant="enclosed"
          size={useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' })}
          colorScheme="green"
        >
          <TabList
            overflowX={{ base: 'scroll', md: 'hidden' }}
            overflowY={'hidden'}
          >
            {categories.map((category) => {
              return (
                <Tab
                  key={category.type}
                  fontWeight={{ base: '400', md: '500' }}
                >
                  {category.name}
                </Tab>
              );
            })}
          </TabList>

          <TabPanels minHeight={{ base: 'sm', lg: 'xl' }}>
            {categories.map((category) => {
              return (
                <TabPanel key={category.type}>
                  <SimpleGrid
                    columns={{ base: 3, md: 4 }}
                    spacingX={{ base: 2, md: 12, lg: 24 }}
                    spacingY={{ base: 6, md: 8, lg: 14 }}
                    mt={{ base: 4, md: 5, lg: 10 }}
                  >
                    {category.elements.map((element) => {
                      return (
                        <Element
                          key={element.title}
                          inView={inView}
                          {...element}
                        />
                      );
                    })}
                  </SimpleGrid>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>

        <Text
          textAlign={'center'}
          fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
          color={'brand.300'}
        >
          Znalazłeś coś, co Cię interesuje?{' '}
          <Link
            color="brand.100"
            fontWeight={{ base: '500', sm: '400' }}
            display={{ base: 'block', sm: 'inline' }}
            href="/#kontakt"
            textDecoration={'underline'}
          >
            Skontaktuj się z nami!
          </Link>
        </Text>
      </Stack>
    </Container>
  );
}

function Element({
  image,
  text,
  title,
  inView,
}: ProductElement & { inView: boolean }) {
  return (
    <Box>
      <Image
        minHeight={{ base: 20, md: 26, lg: 40 }}
        maxH={{ base: 32, md: 36, lg: 40 }}
        alt={title}
        src={inView ? image : undefined}
      />

      <Heading
        fontSize={{ base: 'sm', md: 'lg', lg: 'xl' }}
        fontWeight={{ base: 400, lg: 500 }}
        lineHeight="shorter"
      >
        {title}
      </Heading>

      <Text
        mt={{ lg: '2' }}
        display={{ base: 'none', lg: 'block ' }}
        fontSize="sm"
        color={'gray.600'}
      >
        {text}
      </Text>
    </Box>
  );
}
